import axios from "axios";

export default {
  async getAuctionSearch(auction_center, season_sale) {
    return await axios
      .get(
        `weigh-up-tasting/auction-search?auction_center=${auction_center}&season_sale=${season_sale}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getPrivateSearch(season_vendor_offer_id) {
    return await axios
      .get(
        `weigh-up-tasting/private-search?season_vendor_offer_id=${season_vendor_offer_id}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async saveData(data) {
    return await axios
      .post(`weigh-up-tasting`, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getAuctionDownload(auction_center, season_sale) {
    await axios({
      method: "get",
      url: `weigh-up-tasting/download-auction`,
      params: {
        auction_center,
        season_sale,
      },
      responseType: "blob",
    })
      .then((res) => {
        console.log("RES", res);
        const url = URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.ms-excel",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Auction-weigh-up.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
        throw new Error(err.response.data.message);
      });
  },
  async getPrivateDownload(req) {
    axios({
      method: "get",
      url: `weigh-up-tasting/download-private`,
      params: req,
      responseType: "blob",
    })
      .then((res) => {
        console.log("RES", res.data);
        const url = URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.ms-excel",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Private-weigh-up.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
        throw new Error(err.response.data.message);
      });
  },
};
