<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-0">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      Weigh Up Tasting
                    </h5>
                  </div>
                </div>
              </div>

              <v-card-text>
                <v-row>
                  <v-col cols="12" md="4">
                    <label class="text-md text-typo font-weight-bolder ms-1">
                      Type</label
                    >
                    <v-select
                      :items="types"
                      item-text="type"
                      item-value="type"
                      hide-details="auto"
                      v-model="type"
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        border-radius-md
                        select-style
                        mt-3
                        mb-0
                      "
                      outlined
                      height="46"
                      @change="typeChange()"
                      single-line
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <!--  Auction tasting -->
            <v-card v-show="auction_show" class="card-shadow border-radius-xl">
              <div class="ma-5">
                <v-form ref="frmAuctionFilter">
                  <!-- Style Fix -->
                  <v-row>
                    <v-col></v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="12" md="3">
                      <label class="text-md text-typo font-weight-bolder ms-1"
                        ><span class="red--text"><strong>* </strong></span
                        >Auction center</label
                      >

                      <v-select
                        :items="auctionCenters"
                        item-text="auction_center_name"
                        item-value="id"
                        hide-details="auto"
                        v-model="auctionCenter"
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                          border-radius-md
                          select-style
                          mt-5
                          mb-0
                        "
                        outlined
                        height="46"
                        single-line
                        :rules="[(v) => !!v || 'Auction center is required']"
                        placeholder="Select auction center"
                        @change="resetCenter"
                      >
                        <template v-slot:selection="{ item }">
                          <v-text
                            label
                            color="bg-default"
                            class="py-1 px-2 my-0"
                          >
                            <span class="text-caption ls-0">{{
                              item.auction_center_name
                            }}</span>
                          </v-text>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                      <label class="text-md text-typo font-weight-bolder ms-1"
                        ><span class="red--text"><strong>* </strong></span
                        >Season</label
                      >

                      <v-select
                        :items="seasons"
                        item-value="id"
                        hide-details="auto"
                        v-model="season"
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                          border-radius-md
                          select-style
                          mt-5
                          mb-0
                        "
                        outlined
                        height="46"
                        @change="filterSeasonSale()"
                        single-line
                        :rules="[(v) => !!v || 'Season is required']"
                        placeholder="Select Season"
                      >
                        <template v-slot:selection="{ item }">
                          <v-text
                            name="ss_name"
                            label
                            color="bg-default"
                            class="py-1 px-2 my-0"
                          >
                            <span class="text-caption ls-0">{{
                              item.name
                            }}</span>
                          </v-text>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-text
                            name="ss_name"
                            label
                            color="bg-default"
                            class="py-1 px-2 my-0"
                          >
                            <span class="text-caption ls-0">{{
                              item.name
                            }}</span>
                          </v-text>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                      <label class="text-md text-typo font-weight-bolder ms-1"
                        ><span class="red--text"><strong>* </strong></span>Sale
                        No</label
                      >

                      <v-select
                        :items="seasonSales"
                        item-value="id"
                        hide-details="auto"
                        v-model="seasonSale"
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                          border-radius-md
                          select-style
                          mt-5
                          mb-0
                        "
                        outlined
                        height="46"
                        single-line
                        :rules="[(v) => !!v || 'Sale No is required']"
                        placeholder="Select Sale No"
                      >
                        <template v-slot:selection="{ item }">
                          <v-text
                            name="ss_name"
                            label
                            color="bg-default"
                            class="py-1 px-2 my-0"
                          >
                            <span class="text-caption ls-0">{{
                              item.season_sale
                            }}</span>
                          </v-text>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-text
                            name="ss_name"
                            label
                            color="bg-default"
                            class="py-1 px-2 my-0"
                          >
                            <span class="text-caption ls-0">{{
                              item.season_sale
                            }}</span>
                          </v-text>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex justify-end mx-5">
                      <v-btn
                        @click="clearAuction"
                        elevation="0"
                        :ripple="false"
                        height="46"
                        class="
                          font-weight-bold
                          text-capitalize
                          btn-ls btn-secondary
                          bg-light
                          px-6
                          mr-2
                        "
                        >Clear</v-btn
                      >

                      <v-btn
                        @click="searchAuction()"
                        elevation="0"
                        :ripple="false"
                        height="46"
                        dark
                        class="
                          font-weight-bold
                          text-capitalize
                          btn-ls btn-primary
                          bg-success
                          px-6
                        "
                        >Search</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </div>
            </v-card>

            <v-card
              class="card-shadow border-radius-xl mt-6"
              v-show="showAuctionTable"
              id="scroll_1"
            >
              <v-card-text class="px-0 py-0">
                <v-form ref="frmTable">
                  <v-card-text class="card-padding">
                    <v-data-table
                      v-model="Selection"
                      :headers="auctionHeader"
                      :items="auctionTable"
                      item-key="tid"
                      fixed-header
                      class="table"
                      :footer-props="{
                        'items-per-page-options': [100, 200, 500, -1],
                      }"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title></v-toolbar-title>
                          <v-row
                            ><v-col cols="3">
                              <v-menu
                                disabled
                                ref="mnu_date"
                                v-model="mnu_date"
                                :close-on-content-click="false"
                                :return-value.sync="mnu_date"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="date"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    hide-details="auto"
                                    class="
                                      input-style
                                      font-size-input
                                      text-light-input
                                      placeholder-light
                                      input-icon
                                      mt-5
                                    "
                                    dense
                                    flat
                                    filled
                                    solo
                                    height="43"
                                    placeholder="Date"
                                    persistent-hint
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  color="green lighten-1"
                                  header-color="primary"
                                  v-model="date"
                                  no-title
                                  scrollable
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="mnu_date = false"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.mnu_date.save(date)"
                                  >
                                    OK
                                  </v-btn>
                                </v-date-picker>
                              </v-menu>
                            </v-col></v-row
                          >
                          <v-spacer></v-spacer>

                          <v-btn
                            elevation="0"
                            :ripple="false"
                            height="46"
                            dark
                            @click="DownloadAuction"
                            class="
                              font-weight-bold
                              text-capitalize
                              btn-ls btn-primary
                              bg-success
                              py-3
                              px-6
                              mb-5
                              mt-5
                              mr-5
                            "
                            >Export to Excel
                          </v-btn>

                          <v-btn
                            elevation="0"
                            :ripple="false"
                            height="46"
                            dark
                            @click="submitAuction"
                            class="
                              font-weight-bold
                              text-capitalize
                              btn-ls btn-primary
                              bg-success
                              py-3
                              px-6
                              mb-5
                              mt-5
                            "
                            >Submit
                          </v-btn>
                        </v-toolbar>
                      </template>

                      <template
                        v-slot:[`item.standard_used_from_week_no`]="{
                          item,
                          index,
                        }"
                      >
                        <div>
                          <v-text-field
                            v-model="item.standard_used_from_week_no"
                            hide-details="auto"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                              mt-2
                              mb-4
                              centered-input
                            "
                            dense
                            @keyup="changeFocusInput"
                            :ref="`input-${index * 9 + 1}`"
                            flat
                            filled
                            solo
                            height="46"
                            persistent-hint
                          ></v-text-field>
                        </div>
                      </template>
                      <template v-slot:[`item.approved`]="{ item, index }">
                        <div>
                          <v-select
                            v-model.number="item.approved"
                            :items="status"
                            item-text="status"
                            item-value="status"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              border-radius-md
                              select-style
                              mb-0
                              mt-5
                            "
                            outlined
                            single-line
                            height="46"
                            @keyup="changeFocusInput"
                            :ref="`input-${index * 9 + 7}`"
                          >
                          </v-select>
                        </div>
                      </template>
                      <template
                        v-slot:[`item.below_statndard`]="{ item, index }"
                      >
                        <div>
                          <v-text-field
                            v-model="item.below_statndard"
                            hide-details="auto"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                              mt-2
                              mb-4
                              centered-input
                            "
                            dense
                            flat
                            filled
                            solo
                            height="46"
                            @keyup="changeFocusInput"
                            :ref="`input-${index * 9 + 8}`"
                            persistent-hint
                          ></v-text-field>
                        </div>
                      </template>
                      <template v-slot:[`item.remarks`]="{ item, index }">
                        <div>
                          <v-text-field
                            v-model="item.remarks"
                            hide-details="auto"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                              mt-2
                              mb-4
                              centered-input
                            "
                            dense
                            flat
                            filled
                            @keyup="changeFocusInput"
                            :ref="`input-${index * 9 + 9}`"
                            solo
                            height="46"
                            persistent-hint
                          ></v-text-field>
                        </div>
                      </template>

                      <template v-slot:[`item.generated_item_name`]="{ item }">
                        <v-text-field
                          disabled
                          v-model="item.generated_item_name"
                          hide-details="auto"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            input-icon
                            mt-2
                            mb-4
                            centered-input
                          "
                          dense
                          flat
                          filled
                          solo
                          height="46"
                          persistent-hint
                        ></v-text-field>
                      </template>
                      <template v-slot:[`item.special_marking`]="{ item }">
                        <v-text-field
                          tabindex="-1"
                          v-model="item.special_marking"
                          @input="getItemName(item)"
                          hide-details="auto"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            input-icon
                            mt-2
                            mb-4
                            centered-input
                          "
                          dense
                          flat
                          filled
                          solo
                          height="46"
                          :value="item.special_marking"
                          persistent-hint
                          @keyup="changeFocusInput"
                          style="width: 100px"
                        ></v-text-field>
                      </template>

                      <template
                        v-slot:[`item.leaf_appearance`]="{ item, index }"
                      >
                        <v-select
                          v-model.number="item.leaf_appearance"
                          :items="leaf_appearance"
                          item-text="value"
                          item-value="value"
                          @change="getItemName(item)"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mb-0
                            mt-5
                          "
                          @keyup="changeFocusInput"
                          :ref="`input-${index * 9 + 3}`"
                          outlined
                          single-line
                          height="46"
                        >
                        </v-select>
                      </template>

                      <template v-slot:[`item.leaf_size`]="{ item, index }">
                        <v-select
                          v-model.number="item.leaf_size"
                          :items="leaf_size"
                          item-text="value"
                          item-value="value"
                          @change="getItemName(item)"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mb-0
                            mt-5
                          "
                          @keyup="changeFocusInput"
                          :ref="`input-${index * 9 + 2}`"
                          outlined
                          single-line
                          height="46"
                        >
                        </v-select>
                      </template>

                      <template v-slot:[`item.color`]="{ item, index }">
                        <v-select
                          v-model.number="item.color"
                          :items="color"
                          item-text="value"
                          item-value="value"
                          @change="getItemName(item)"
                          @keyup="changeFocusInput"
                          :ref="`input-${index * 9 + 4}`"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mb-0
                            mt-5
                          "
                          outlined
                          single-line
                          height="46"
                        >
                        </v-select>
                      </template>
                      <template v-slot:[`item.body`]="{ item, index }">
                        <v-select
                          v-model.number="item.body"
                          :items="body"
                          item-text="value"
                          item-value="value"
                          @change="getItemName(item)"
                          @keyup="changeFocusInput"
                          :ref="`input-${index * 9 + 5}`"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mb-0
                            mt-5
                          "
                          outlined
                          single-line
                          height="46"
                        >
                        </v-select>
                      </template>
                      <template v-slot:[`item.taste`]="{ item, index }">
                        <v-select
                          v-model.number="item.taste"
                          @keyup="changeFocusInput"
                          :ref="`input-${index * 9 + 6}`"
                          :items="taste"
                          item-text="value"
                          item-value="value"
                          @change="getItemName(item)"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mb-0
                            mt-5
                          "
                          outlined
                          single-line
                          height="46"
                        >
                        </v-select>
                      </template>
                      <template v-slot:[`item.comment`]="{ item }">
                        <v-text-field
                          v-model="item.comment"
                          hide-details="auto"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            input-icon
                            mt-2
                            mb-4
                            centered-input
                          "
                          dense
                          flat
                          filled
                          solo
                          height="46"
                          :value="item.comment"
                          persistent-hint
                        ></v-text-field>
                      </template>
                    </v-data-table>
                  </v-card-text> </v-form></v-card-text
              ><v-card-action>
                <v-row>
                  <v-col offset-md="5">
                    <v-btn
                      elevation="0"
                      :ripple="false"
                      height="46"
                      dark
                      @click="submitAuction"
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-primary
                        bg-success
                        py-3
                        px-6
                        mb-5
                        mt-5
                      "
                      >Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-action>
            </v-card>

            <!--  -->
            <!--  -->
            <!-- Private Tasting -->

            <v-card class="card-shadow border-radius-xl" v-show="private_show">
              <div class="ma-5">
                <v-form ref="frmprivateFilter">
                  <!-- Style Fix -->
                  <v-row>
                    <v-col></v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="12" md="3">
                      <label class="text-md font-weight-bolder ms-1 mandatory"
                        ><span class="red--text"><strong>* </strong></span
                        >Vendor</label
                      >
                      <v-autocomplete
                        v-model="vendor"
                        :items="vendors"
                        item-text="name"
                        item-value="id"
                        return-object
                        color="rgba(0,0,0,.6)"
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                          border-radius-md
                          select-style
                          mt-5
                          mb-0
                        "
                        placeholder="Select a Vendor"
                        :rules="[(v) => !!v || 'Vendor is Required']"
                        outlined
                        single-line
                        height="46"
                        @change="getSeason"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <label class="text-md font-weight-bolder ms-1 mandatory"
                        ><span class="red--text"><strong>* </strong></span
                        >Season</label
                      >
                      <v-autocomplete
                        return-object
                        v-model="season"
                        :items="seasons"
                        item-text="name"
                        item-value="id"
                        color="rgba(0,0,0,.6)"
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                          border-radius-md
                          select-style
                          mt-5
                          mb-0
                        "
                        placeholder="Select a Season"
                        :rules="[(v) => !!v || 'Season is Required']"
                        outlined
                        single-line
                        height="46"
                        @change="getOffers"
                      >
                      </v-autocomplete> </v-col
                    ><v-col cols="12" sm="3">
                      <label class="text-md font-weight-bolder ms-1 mandatory"
                        ><span class="red--text"><strong>* </strong></span>Offer
                        No</label
                      >
                      <v-autocomplete
                        return-object
                        v-model="offer"
                        :items="offers"
                        item-text="offer_no"
                        color="rgba(0,0,0,.6)"
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                          border-radius-md
                          select-style
                          mt-5
                          mb-0
                        "
                        placeholder="Select a Offer"
                        :rules="[(v) => !!v || 'Offer is Required']"
                        outlined
                        single-line
                        height="46"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex justify-end ma-5">
                      <v-btn
                        @click="clearPrivate"
                        elevation="0"
                        :ripple="false"
                        height="46"
                        class="
                          font-weight-bold
                          text-capitalize
                          btn-ls btn-secondary
                          bg-light
                          px-6
                          mr-2
                        "
                        >Clear</v-btn
                      >
                      <v-btn
                        @click="searchPrivate()"
                        elevation="0"
                        :ripple="false"
                        height="46"
                        dark
                        class="
                          font-weight-bold
                          text-capitalize
                          btn-ls btn-primary
                          bg-success
                          px-6
                        "
                        >Search</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </div>
            </v-card>
            <v-card
              class="card-shadow border-radius-xl mt-6"
              v-show="show_private_table"
              id="scroll_2"
            >
              <v-card-text class="px-0 py-0">
                <v-form ref="frmTable">
                  <v-card-text class="card-padding">
                    <v-data-table
                      v-model="Selection"
                      :headers="privateHeader"
                      :items="privateTable"
                      item-key="tid"
                      fixed-header
                      show-select
                      hide-default-footer
                      class="table"
                      disable-pagination
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title></v-toolbar-title>
                          <v-row
                            ><v-col cols="3">
                              <v-menu
                                ref="mnu_date1"
                                v-model="mnu_date1"
                                :close-on-content-click="false"
                                :return-value.sync="mnu_date"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="date1"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    hide-details="auto"
                                    class="
                                      input-style
                                      font-size-input
                                      text-light-input
                                      placeholder-light
                                      input-icon
                                      mt-5
                                    "
                                    dense
                                    flat
                                    filled
                                    solo
                                    height="43"
                                    placeholder="Date"
                                    persistent-hint
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  color="green lighten-1"
                                  header-color="primary"
                                  v-model="date1"
                                  no-title
                                  scrollable
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="mnu_date1 = false"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.mnu_date1.save(date1)"
                                  >
                                    OK
                                  </v-btn>
                                </v-date-picker>
                              </v-menu>
                            </v-col></v-row
                          >
                          <v-spacer></v-spacer>
                          <v-btn
                            elevation="0"
                            :ripple="false"
                            height="46"
                            dark
                            @click="downloadPrivate"
                            class="
                              font-weight-bold
                              text-capitalize
                              btn-ls btn-primary
                              bg-success
                              py-3
                              px-6
                              mb-5
                              mt-5
                              mr-5
                            "
                            >Export to Excel
                          </v-btn>
                          <v-btn
                            elevation="0"
                            :ripple="false"
                            height="46"
                            dark
                            @click="submitPrivate"
                            class="
                              font-weight-bold
                              text-capitalize
                              btn-ls btn-primary
                              bg-success
                              py-3
                              px-6
                              mb-5
                              mt-5
                            "
                            >Submit
                          </v-btn>
                        </v-toolbar>
                      </template>

                      <template
                        v-slot:[`item.standard_used_from_week_no`]="{
                          item,
                          index,
                        }"
                      >
                        <div>
                          <v-text-field
                            v-model="item.standard_used_from_week_no"
                            hide-details="auto"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                              mt-2
                              mb-4
                              centered-input
                            "
                            dense
                            flat
                            filled
                            solo
                            height="46"
                            @keyup="changeFocusInput"
                            :ref="`input-${index * 9 + 1}`"
                            persistent-hint
                          ></v-text-field>
                        </div>
                      </template>
                      <template v-slot:[`item.approved`]="{ item, index }">
                        <div>
                          <v-select
                            v-model.number="item.approved"
                            :items="status"
                            item-text="status"
                            item-value="status"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              border-radius-md
                              select-style
                              mb-0
                              mt-5
                            "
                            @keyup="changeFocusInput"
                            :ref="`input-${index * 9 + 7}`"
                            outlined
                            single-line
                            height="46"
                          >
                          </v-select>
                        </div>
                      </template>
                      <template
                        v-slot:[`item.below_statndard`]="{ item, index }"
                      >
                        <div>
                          <v-text-field
                            v-model="item.below_statndard"
                            hide-details="auto"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                              mt-2
                              mb-4
                              centered-input
                            "
                            dense
                            flat
                            filled
                            solo
                            @keyup="changeFocusInput"
                            :ref="`input-${index * 9 + 8}`"
                            height="46"
                            persistent-hint
                          ></v-text-field>
                        </div>
                      </template>
                      <template v-slot:[`item.remarks`]="{ item, index }">
                        <div>
                          <v-text-field
                            v-model="item.remarks"
                            hide-details="auto"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                              mt-2
                              mb-4
                              centered-input
                            "
                            dense
                            flat
                            filled
                            solo
                            @keyup="changeFocusInput"
                            :ref="`input-${index * 9 + 9}`"
                            height="46"
                            persistent-hint
                          ></v-text-field>
                        </div>
                      </template>

                      <template v-slot:[`item.generated_item_name`]="{ item }">
                        <v-text-field
                          disabled
                          v-model="item.generated_item_name"
                          hide-details="auto"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            input-icon
                            mt-2
                            mb-4
                            centered-input
                          "
                          dense
                          flat
                          filled
                          solo
                          height="46"
                          persistent-hint
                        ></v-text-field>
                      </template>
                      <template v-slot:[`item.special_marking`]="{ item }">
                        <v-text-field
                          tabindex="-1"
                          v-model="item.special_marking"
                          @input="getItemName(item)"
                          hide-details="auto"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            input-icon
                            mt-2
                            mb-4
                            centered-input
                          "
                          dense
                          flat
                          filled
                          solo
                          height="46"
                          :value="item.special_marking"
                          persistent-hint
                          @keyup="changeFocusInput"
                          style="width: 100px"
                        ></v-text-field>
                      </template>

                      <template
                        v-slot:[`item.leaf_appearance`]="{ item, index }"
                      >
                        <v-select
                          v-model.number="item.leaf_appearance"
                          :items="leaf_appearance"
                          item-text="value"
                          item-value="value"
                          @change="getItemName(item)"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mb-0
                            mt-5
                          "
                          @keyup="changeFocusInput"
                          :ref="`input-${index * 9 + 3}`"
                          outlined
                          single-line
                          height="46"
                        >
                        </v-select>
                      </template>

                      <template v-slot:[`item.leaf_size`]="{ item, index }">
                        <v-select
                          v-model.number="item.leaf_size"
                          :items="leaf_size"
                          item-text="value"
                          item-value="value"
                          @change="getItemName(item)"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mb-0
                            mt-5
                          "
                          @keyup="changeFocusInput"
                          :ref="`input-${index * 9 + 2}`"
                          outlined
                          single-line
                          height="46"
                        >
                        </v-select>
                      </template>

                      <template v-slot:[`item.color`]="{ item, index }">
                        <v-select
                          v-model.number="item.color"
                          :items="color"
                          item-text="value"
                          item-value="value"
                          @change="getItemName(item)"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mb-0
                            mt-5
                          "
                          @keyup="changeFocusInput"
                          :ref="`input-${index * 9 + 4}`"
                          outlined
                          single-line
                          height="46"
                        >
                        </v-select>
                      </template>
                      <template v-slot:[`item.body`]="{ item, index }">
                        <v-select
                          v-model.number="item.body"
                          :items="body"
                          item-text="value"
                          item-value="value"
                          @change="getItemName(item)"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mb-0
                            mt-5
                          "
                          @keyup="changeFocusInput"
                          :ref="`input-${index * 9 + 5}`"
                          outlined
                          single-line
                          height="46"
                        >
                        </v-select>
                      </template>
                      <template v-slot:[`item.taste`]="{ item, index }">
                        <v-select
                          v-model.number="item.taste"
                          :items="taste"
                          item-text="value"
                          item-value="value"
                          @change="getItemName(item)"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mb-0
                            mt-5
                          "
                          @keyup="changeFocusInput"
                          :ref="`input-${index * 9 + 6}`"
                          outlined
                          single-line
                          height="46"
                        >
                        </v-select>
                      </template>
                      <template v-slot:[`item.comment`]="{ item }">
                        <v-text-field
                          v-model="item.comment"
                          hide-details="auto"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            input-icon
                            mt-2
                            mb-4
                            centered-input
                          "
                          dense
                          flat
                          filled
                          solo
                          height="46"
                          :value="item.comment"
                          persistent-hint
                        ></v-text-field>
                      </template> </v-data-table
                  ></v-card-text> </v-form
              ></v-card-text>
              <v-card-action>
                <v-row>
                  <v-col offset-md="5">
                    <v-btn
                      elevation="0"
                      :ripple="false"
                      height="46"
                      dark
                      @click="submitPrivate"
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-primary
                        bg-success
                        py-3
                        px-6
                        mb-5
                        mt-5
                      "
                      >Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-action>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import apiMaster from "../../../master/master-get-api";
import blend from "../../../../api/blend-api";
import api from "../api";
export default {
  data() {
    return {
      leaf_appearance: [],
      leaf_size: [],
      color: [],
      body: [],
      taste: [],
      //
      overlay: false,
      auction_show: false,
      private_show: false,
      auctionCenters: [],
      types: [{ type: "PRIVATE" }, { type: "AUCTION" }],
      status: [{ status: "APPROVED" }, { status: "REJECT" }],
      type: "",

      auctionCenter: "",
      seasons: [],
      season: "",
      seasonSales: [],
      showAuctionTable: false,
      seasonSale: "",
      auctionTable: [],
      auctionHeader: [
        {
          text: "Material",
          width: 50,
          value: "material",
          align: "left",

          sortable: false,
        },
        {
          text: "QTY Purchased",
          width: 50,
          value: "total_net_kgs",
          align: "left",

          sortable: false,
        },
        {
          text: "AVG.Price",
          width: 50,
          value: "purchased_rate",
          align: "left",

          sortable: false,
        },
        {
          text: "Standard Used from Week No",
          width: 50,
          value: "standard_used_from_week_no",
          align: "left",

          sortable: false,
        },
        {
          text: "Appearance",
          width: 50,
          value: "leaf_appearance",
          align: "left",

          sortable: false,
        },
        {
          text: "Size",
          width: 50,
          value: "leaf_size",
          align: "left",

          sortable: false,
        },

        {
          text: "Colour",
          width: 50,
          value: "color",
          align: "left",

          sortable: false,
        },
        {
          text: "Body",
          width: 50,
          value: "body",
          align: "left",

          sortable: false,
        },
        {
          text: "Taste",
          width: 50,
          value: "taste",
          align: "left",

          sortable: false,
        },
        {
          text: "Generated",
          width: 50,
          value: "generated_item_name",
          align: "left",

          sortable: false,
        },

        {
          text: "Approved",
          width: 50,
          value: "approved",
          align: "left",

          sortable: false,
        },
        {
          text: "Below Standard",
          width: 100,
          value: "below_statndard",
          align: "left",

          sortable: false,
        },
        {
          text: "Remarks",
          width: 100,
          value: "remarks",
          align: "left",

          sortable: false,
        },
        {
          text: "special marking",
          width: 100,
          value: "special_marking",
          align: "left",

          sortable: false,
        },
      ],
      Selection: [],

      // Private

      vendors: [],
      vendor: null,
      seasons: [],
      season: null,
      offer: null,
      offers: [],
      privateTable: [],
      //
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_date: false,
      date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_date1: false,
      privateHeader: [
        {
          text: "Material",
          width: 100,
          value: "material",
          align: "left",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
        },
        {
          text: "QTY Purchased",
          width: 100,
          value: "total_net_kgs",
          align: "left",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
        },
        {
          text: "AVG.Price",
          width: 100,
          value: "purchased_rate",
          align: "left",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
        },
        {
          text: "Standard Used from Week No",
          width: 150,
          value: "standard_used_from_week_no",
          align: "left",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
        },
        {
          text: "Appearance",
          width: 100,
          value: "leaf_appearance",
          align: "left",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
        },
        {
          text: "Size",
          width: 100,
          value: "leaf_size",
          align: "left",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
        },

        {
          text: "Colour",
          width: 100,
          value: "color",
          align: "left",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
        },
        {
          text: "Body",
          width: 100,
          value: "body",
          align: "left",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
        },
        {
          text: "Taste",
          width: 100,
          value: "taste",
          align: "left",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
        },
        {
          text: "Generated",
          width: 100,
          value: "generated_item_name",
          align: "left",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
        },

        {
          text: "Approved",
          width: 100,
          value: "approved",
          align: "left",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
        },
        {
          text: "Below Standard",
          width: 100,
          value: "below_statndard",
          align: "left",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
        },
        {
          text: "Remarks",
          width: 100,
          value: "remarks",
          align: "left",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
        },
        {
          text: "special marking",
          width: 100,
          value: "special_marking",
          align: "left",
          class: "text-secondary font-weight-bolder opacity-10",
          sortable: false,
        },
      ],
      Selection: [],
      show_private_table: false,
      currentItem: 1,
    };
  },
  watch: {
    currentItem: {
      handler(value, oldValue) {
        if (value !== oldValue) {
          this.focusInput(value);
        }
      },
      immediate: true,
    },
  },
  methods: {
    getIndex(item) {
      return this.myAllocation.indexOf(item);
    },

    getInput(id) {
      const input = this.$refs[`input-${id}`];
      return input;
    },
    changeFocusInput(event) {
      const key_code = {
        left: 37,
        up: 38,
        right: 39,
        down: 40,
      };

      let nextItem = this.currentItem;

      if (event.keyCode == key_code.left) {
        nextItem -= 1;
      } else if (event.keyCode == key_code.right) {
        nextItem += 1;
      }

      if (this.getInput(nextItem)) {
        this.currentItem = nextItem;
      }
    },
    focusInput(id) {
      const input = this.getInput(id);
      if (input) {
        input.focus();
      }
    },

    async filterSeasonSale() {
      this.overlay = true;
      this.seasonSale = "";
      this.seasonSales = [];
      this.seasonSale = 0;
      this.seasonSales = await apiMaster.getSeasonSaleBySeasonAndAuctionCenter(
        this.season,
        this.auctionCenter
      );
      this.overlay = false;
    },
    async initialize() {
      this.overlay = true;
      let result = await blend.getBlendfactor();
      this.body = result.body;
      this.leaf_size = result.leaf_size;
      this.color = result.color;
      this.taste = result.taste;
      this.leaf_appearance = result.leaf_appearance;
      this.auctionCenters = await apiMaster.getAuctionCenter();
      this.seasons = await apiMaster.getSeason();
      // this.seasonSales = await apiMaster.getSeasonSale();
      this.vendors = await apiMaster.getPrivateVendors();
      this.overlay = false;
    },

    validateAuctionSearcg() {
      // return this.$refs;
    },
    getSeasonSale() {},

    getItemName(item) {
      if (
        item.body > 0 &&
        item.color > 0 &&
        item.leaf_appearance > 0 &&
        item.leaf_size > 0 &&
        item.taste > 0
      ) {
        blend
          .getItemName(
            item.body,
            item.color,
            item.leaf_appearance,
            item.leaf_size,
            item.taste
          )
          .then((res) => {
            item.item_name_1 = res.data.name;
            item.generated_item_name = this.setItemName(item);
          });
      }
    },
    setItemName(item) {
      if (item.special_marking) {
        return `${item.item_name_1}${item.special_marking}`;
      } else {
        return item.item_name_1;
      }
    },
    scrollDown() {
      this.$nextTick(() =>
        document
          .getElementById("scroll_1")
          .scrollIntoView({ behavior: "smooth" })
      );
    },
    scrollDownPrivate() {
      this.$nextTick(() =>
        document
          .getElementById("scroll_2")
          .scrollIntoView({ behavior: "smooth" })
      );
    },

    auctionSearchValidation() {
      return this.$refs.frmAuctionFilter.validate();
    },
    resetCenter() {
      this.season = "";
      this.seasonSale = "";
      this.seasonSales = [];
    },
    async searchAuction() {
      if (this.auctionSearchValidation()) {
        this.showAuctionTable = false;
        this.auctionTable = [];
        this.Selection = [];

        this.overlay = true;
        this.auctionTable = await api.getAuctionSearch(
          this.auctionCenter,
          this.seasonSale
        );
        if (this.auctionTable.length > 0) {
          this.Selection = this.auctionTable;
          this.showAuctionTable = true;
          this.auctionTable.forEach((el, i) => {
            el.tid = i;
          });
          this.scrollDown();
        } else {
          this.showAuctionTable = false;
          this.noDataAlert("No Data Found");
        }

        this.overlay = false;
      }
    },

    privateSearchValidation() {
      return this.$refs.frmprivateFilter.validate();
    },

    async searchPrivate() {
      if (this.privateSearchValidation()) {
        this.Selection = [];
        this.privateTable = [];
        this.show_private_table = false;
        this.overlay = true;
        this.privateTable = await api.getPrivateSearch(this.offer.id);
        if (this.privateTable.length > 0) {
          this.privateTable.forEach((el, i) => {
            el.tid = i;
          });
          this.scrollDownPrivate();
          this.Selection = this.privateTable;
          this.show_private_table = true;
        } else {
          this.noDataAlert("No Data Found");
        }

        this.overlay = false;
      }
    },
    async getSeason() {
      try {
        this.seasons = [];
        this.season = null;
        this.offer = null;
        this.offers = [];
        this.overlay = true;
        this.seasons = await apiMaster.getSeasonByVendor(this.vendor.id);
      } catch (error) {
      } finally {
        this.overlay = false;
      }
    },

    async getOffers() {
      try {
        this.offers = [];
        this.offer = null;
        this.overlay = true;
        this.offers = await apiMaster.getOffersByvendorSeason(
          this.vendor.id,
          this.season.id
        );
      } catch (error) {
      } finally {
        this.overlay = false;
      }
    },
    async submitAuction() {
      this.overlay = true;
      try {
        if (this.Selection.length > 0) {
          let data = {
            auction_center: this.auctionCenter,
            season_sale: this.seasonSale,
            date: this.date,
            data: this.Selection,
          };
          await api.saveData(data);
          this.showSuccessAlert("Data Saved...");
        } else {
          this.showWarningAlert("Select Alteast One row");
        }

        // this.clear();
      } catch (error) {}

      this.Selection = [];
      this.overlay = false;
    },
    async submitPrivate() {
      this.overlay = true;
      try {
        if (this.Selection.length > 0) {
          let data = {
            data: this.Selection,
            date: this.date1,
            vendor: this.vendor.id,
            season_vendor_offer_id: this.offer.id,
          };
          await api.saveData(data);
          this.showSuccessAlert("Data Saved...");
          this.Selection = [];
        } else {
          this.showWarningAlert("Select Alteast One row");
        }

        // this.clear();
      } catch (error) {
        this.showErrorAlert(error);
      }

      this.Selection = [];
      this.auctionTable = [];
      this.overlay = false;
    },
    clear() {
      this.auctionCenter = "";
      this.seasonSale = "";
      this.vendor = null;
      this.offer = null;
    },
    typeChange() {
      this.show_private_table = false;
      this.showAuctionTable = false;
      this.clear();
      if (this.type == "AUCTION") {
        this.auction_show = true;
        this.private_show = false;
      } else if (this.type == "PRIVATE") {
        this.auction_show = false;
        this.private_show = true;
      }
    },
    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        showConfirmButton: false,
        type: "error",
        timer: 3000,
        icon: "error",
      });
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },
    noDataAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,
        timer: 3000,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
    clearAuction() {
      this.auctionCenter = "";

      this.season = "";
      this.seasonSales = [];
      this.seasonSale = "";
      this.$refs.frmAuctionFilter.resetValidation();
    },
    clearPrivate() {
      this.vendor = null;
      this.season = null;
      this.seasons = [];
      this.offer = null;
      this.offers = [];
      this.$refs.frmprivateFilter.resetValidation();
    },
    async DownloadAuction() {
      this.overlay = true;
      try {
        await api.getAuctionDownload(this.auctionCenter, this.seasonSale);
        // this.clear();
      } catch (error) {}

      this.Selection = [];
      this.overlay = false;
    },
    async downloadPrivate() {
      this.overlay = true;
      try {
        let req = { season_vendor_offer_id: this.offer.id };
        await api.getPrivateDownload(req);
        // this.clear();
      } catch (error) {}

      this.Selection = [];
      this.overlay = false;
    },
  },
  created() {
    this.initialize();
  },
};
</script>

<style>
.v-data-table {
  overflow-x: auto !important;
}
</style>
